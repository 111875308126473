import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';

export default {
  namespaced: true,

  state: {
    loans: [],
    selectedLoanId: '',
    selectedLoanStatus: '',
    selectedLoanDetails: {},
    //dashboard
    dashboardLoan: {},
    saveForLaterFiles: [],
    selectedTaskId: '',
    propId: '',
    selectedTask: {},
    isSupporting: false,
    // loan progress
    selectedLoanProgress: {},
    newLoanId: '',
    isDashboardPage: 0,
    tasksList: [],
    //loan senario
    loanSenarioData: {},
  },
  getters: {
    loans: ({ loans }) => loans,
    selectedLoanId: ({ selectedLoanId }) => selectedLoanId,
    selectedLoanDetails: ({ selectedLoanDetails }) => selectedLoanDetails,
    dashboardLoan: ({ dashboardLoan }) => dashboardLoan,
    saveLaterFiles: ({ saveForLaterFiles }) => saveForLaterFiles,
    selectedTaskId: ({ selectedTaskId }) => selectedTaskId,
    propId: ({ propId }) => propId,
    selectedTask: ({ selectedTask }) => selectedTask,
    isSupporting: ({ isSupporting }) => isSupporting,
    selectedLoanStatus: ({ selectedLoanStatus }) => selectedLoanStatus,
    selectedLoanProgress: ({ selectedLoanProgress }) => selectedLoanProgress,
    isDashboardPage: ({ isDashboardPage }) => isDashboardPage,
    tasksList: ({ tasksList }) => tasksList,
    loanSenarioData: ({ loanSenarioData }) => loanSenarioData,
    supportingDocuments: ({ supportingDocuments }) => supportingDocuments,
  },
  mutations: {
    setLoanDetails(state, payload) {
      state.loans = payload;
    },
    setLoanId(state, payload) {
      state.selectedLoanId = payload;
    },
    setNewLoanId(state, payload) {
      state.newLoanId = payload;
    },
    setLoanStatus(state, payload) {
      state.selectedLoanStatus = payload;
    },
    setSelectedLoanDetails(state, payload) {
      state.selectedLoanDetails = payload;
    },
    setSaveForLaterFiles(state, payload) {
      state.saveForLaterFiles = payload;
    },
    setSelectedTaskId(state, payload) {
      state.selectedTaskId = payload;
    },
    setSelectedTask(state, payload) {
      state.selectedTask = payload;
    },
    setCrmTaskId(state, payload) {
      state.propId = payload;
    },
    setIsSupporting(state, payload) {
      state.isSupporting = payload;
    },
    setLoanScenarioData(state, payload) {
      state.loanSenarioData = JSON.parse(payload?.applicationData || '');
    },
    // dashboard
    setDashboardLoan(state, payload) {
      if (payload.applicationStatus) {
        state.dashboardLoan = payload;
        switch (payload.applicationStatus) {
          case 'application_submitted':
            state.dashboardLoan.percentage = 20;
            state.dashboardLoan.statusText = 'Application submission';
            break;
          case 'conditional_offer':
            state.dashboardLoan.percentage = 30;
            state.dashboardLoan.statusText = 'Conditional offer';
            break;
          case 'supporting_documents':
            state.dashboardLoan.percentage = 40;
            state.dashboardLoan.statusText = 'Supporting documents';
            break;
          case 'unconditional_offer':
            state.dashboardLoan.percentage = 50;
            state.dashboardLoan.statusText = 'Unconditional offer';
            break;
          case 'loan_settlement':
            state.dashboardLoan.percentage = 60;
            state.dashboardLoan.statusText = 'Loan settlement';
            break;
          case 'loan_funded':
            state.dashboardLoan.percentage = 70;
            state.dashboardLoan.statusText = 'Loan Funded';
            break;
          case 'loan_repaid':
            state.dashboardLoan.percentage = 100;
            state.dashboardLoan.statusText = 'Loan repaid';
            break;
        }
      } else {
        state.dashboardLoan = {};
      }
    },
    setSelectedLoanProgress(state, payload) {
      state.selectedLoanProgress = payload;
    },
    setDashboard(state) {
      state.isDashboardPage += 1;
    },
    setTasksByApplicationId(state, tasks) {
      state.tasksList = tasks;
    },
    setSupportingDocuments(state, payload) {
      state.supportingDocuments = payload;
    },
  },
  actions: {
    async getLoansById({ commit }) {
      const result = await _get(TECHLEND_BE_URL + API.APPLICATION.LIST_APPLICATIONS);
      commit('setLoanDetails', result.data.applications);
    },
    async getApplicationById({ commit }, applicationId) {
      const { data } = await _get(TECHLEND_BE_URL + API.PUBLIC.GET_LOAN_NO_TOKEN + applicationId);
      commit('setLoanScenarioData', data?.result);
    },
    async fetchLoanSummary({ state, commit }) {
      const { data } = await _get(
        TECHLEND_BE_URL +
          API.APPLICATION.RETRIEVE_APPLICATION +
          `?id=${state?.selectedLoanId || state?.dashboardLoan?.id}`,
      );
      if (data) {
        commit('setSelectedLoanDetails', data?.application);
      } else {
        commit('setSelectedLoanDetails', {});
      }
    },
    setLoanId({ commit }, id) {
      commit('setLoanId', id);
    },
    setNewLoanId({ commit }, id) {
      commit('setNewLoanId', id);
    },
    setLoanStatus({ commit }, id) {
      commit('setLoanStatus', id);
    },
    async fetchLoanProgress({ state, commit /* state */ }) {
      // TODO: fix task list and edit
      const result = await _get(
        TECHLEND_BE_URL +
          API.TASKS.LIST_TASKS +
          `?applicationId=${state?.selectedLoanId || state?.dashboardLoan?.id}` /* + state.dashboardLoan.id */,
      );
      if (result?.data?.results) {
        commit('setLoanId', result?.data?.results.Id);
      }
      commit('setSelectedLoanProgress', result?.data?.results ? result?.data?.results : {});
      // return await _get(SERVER_URL_4 + 'user-application-progress?id=' + state.selectedLoanId)
    },
    async fetchSupportingDocuments({ state, commit }) {
      const { data } = await _get(
        `${TECHLEND_BE_URL}${API.TASKS.LIST_TASKS}?isStatusBeyondRange=true` +
          `&applicationId=${state?.selectedLoanId || state?.dashboardLoan?.id}`,
      );
      const { tasks } = data;
      const supportingDocuments = tasks.filter((task) => task.crmTask.title === 'upload_supporting_document');
      commit('setSupportingDocuments', supportingDocuments);
    },
    async fetchToDoTasks({ state }) {
      await _get(TECHLEND_BE_URL + 'application_progress_task?id=' + state.newLoanId);
    },
    async setSaveForLaterFiles({ commit }, payload) {
      commit('setSaveForLaterFiles', payload);
    },
    setSelectedTaskId({ commit }, id) {
      commit('setSelectedTaskId', id);
    },
    setSelectedTask({ commit }, task) {
      commit('setSelectedTask', task);
    },
    setCrmTaskId({ commit }, id) {
      commit('setCrmTaskId', id);
    },
    setIsSupporting({ commit }, value) {
      commit('setIsSupporting', value);
    },

    //get Dashboard loan
    async getDashboardLoan({ state, commit, rootState }) {
      const { data } = await _get(
        TECHLEND_BE_URL +
          API.APPLICATION.LIST_APPLICATIONS +
          `?applicationId=${state?.selectedLoanId || state?.dashboardLoan?.id}`,
      );
      const activeApplicationId = state?.selectedLoanId || rootState.settings.profileDetails.applicationId;
      const activeApplication = data.applications.find((application) => application.id === activeApplicationId);
      commit('setDashboardLoan', activeApplication || {});
    },

    // get tasks by application
    async getTasksByApplicationId({ state, commit } /* applicationId */) {
      try {
        const { data } = await _get(
          TECHLEND_BE_URL +
            API.TASKS.LIST_TASKS +
            `?applicationId=${state?.selectedLoanId || state?.dashboardLoan?.id}` /* + "/" + applicationId */,
        );
        commit('setTasksByApplicationId', data.tasks);
      } catch (error) {
        console.log(error);
      }
    },

    setDashboard({ commit }, payload) {
      commit('setDashboard', payload);
    },
    getSolictorDetailsById() {
      return false;
    },
  },
};
